import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { useAuthContext } from '@providers/AuthProvider';
// import { useAPI } from '@hooks/useAPI';
import { getWorkspaceJobs } from '@queries/workspaces/getWorkspaceJobs';
import { getAssignedToMeWorkspaceJobs } from '@queries/workspaces/getAssignedToMeWorkspaceJobs';

import { TJob } from 'app/types/entities/TJob';
import { TPaginatedResponse } from 'app/types/API/TPaginatedResponse';

import { StatusFilters } from '../components/Content/components/StatusFilters';

import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';
import { convertDuration } from '@helpers/convertDuration';
import { downloadCSV } from '@helpers/downloadCSV';

type TState = {
  jobs: {
    data: TJob[];
    count: number;
  };
  isLoading: boolean;
  reload: () => void;
  exportCSV: () => void;
  currentTab: 'All Jobs' | 'Assigned To Me';
  setCurrentTab: (v: 'All Jobs' | 'Assigned To Me') => void;
  checkedJobs: string[];
  setCheckedJobs: (v: string[]) => void;
  anchorEl: null | HTMLElement;
  setAnchorEl: (v: null | HTMLElement) => void;
  orderBy: string;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  orderType: string;
  setOrderType: React.Dispatch<React.SetStateAction<string>>;
  statusFilters: StatusFilters;
  setStatusFilters: (v: StatusFilters) => void;
  dateFilter: {
    from?: Date | null;
    to?: Date | null;
  };
  setDateFilter: (v: { from?: Date | null; to?: Date | null }) => void;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
};

const PageContext = createContext<TState>({
  jobs: {
    data: [],
    count: 0,
  },
  isLoading: true,
  reload: () => {},
  exportCSV: () => {},
  currentTab: 'All Jobs',
  setCurrentTab: () => {},
  checkedJobs: [],
  setCheckedJobs: () => {},
  anchorEl: null,
  setAnchorEl: () => {},
  orderBy: 'createdAt',
  setOrderBy: () => {},
  orderType: 'desc',
  setOrderType: () => {},
  statusFilters: {
    all: true,
    JOB_STATUS_DRAFT: false,
    JOB_STATUS_PROCESSING: false,
    JOB_STATUS_TRANSCRIBING: false,
    JOB_STATUS_PROOFING: false,
    JOB_STATUS_COMPLETE: false,
    JOB_STATUS_READY_FOR_TRANSCRIBING: false,
    JOB_STATUS_READY_FOR_PROOFREADING: false,
    JOB_STATUS_READY_FOR_FINAL: false,
    JOB_STATUS_FINALIZING: false,
    AS_ONE_LOCKED: false,
  },
  setStatusFilters: () => {},
  dateFilter: {
    from: undefined,
    to: undefined,
  },
  setDateFilter: () => {},
  search: '',
  setSearch: () => {},
  page: 1,
  setPage: () => {},
  pageSize: 25,
  setPageSize: () => {},
});

export type TPageProviderProps = {
  children: ReactNode;
  workspaceId: string;
};

export const PageProvider = ({ children, workspaceId }: TPageProviderProps) => {
  // const { call } = useAPI();
  const abortController = new AbortController();
  const { organization, workspace } = useAuthContext();
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [orderType, setOrderType] = useState('desc');
  const [statusFilters, setStatusFilters] = useState({
    all: true,
    JOB_STATUS_DRAFT: false,
    JOB_STATUS_PROCESSING: false,
    JOB_STATUS_TRANSCRIBING: false,
    JOB_STATUS_PROOFING: false,
    JOB_STATUS_COMPLETE: false,
    JOB_STATUS_READY_FOR_TRANSCRIBING: false,
    JOB_STATUS_READY_FOR_PROOFREADING: false,
    JOB_STATUS_READY_FOR_FINAL: false,
    JOB_STATUS_FINALIZING: false,
    AS_ONE_LOCKED: false,
  });
  const [dateFilter, setDateFilter] = useState<{
    from?: Date | null;
    to?: Date | null;
  }>({
    from: undefined,
    to: undefined,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const defaultTab = ['all', 'assignedAndUnassigned'].includes(organization?.permissions?.viewJobs)
    ? 'All Jobs'
    : 'Assigned To Me';
  const [currentTab, setCurrentTab] = useState<'All Jobs' | 'Assigned To Me'>(defaultTab);
  const [checkedJobs, setCheckedJobs] = useState<string[]>([]);
  const [jobs, setJobs] = useState<TPaginatedResponse<TJob>>({
    data: [],
    count: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  const endpoint = currentTab === 'All Jobs' ? getWorkspaceJobs : getAssignedToMeWorkspaceJobs;

  const reload = async () => {
    // setJobs({ data: [], count: 0 });
    if (!organization || !workspace) {
      return;
    }
    setCheckedJobs([]);
    setIsLoading(true);
    const statusFilterKeys = Object.keys(statusFilters).filter(
      (k) => k !== 'all' && statusFilters[k as keyof typeof statusFilters],
    );
    const data = await endpoint(
      page,
      pageSize,
      orderBy,
      orderType,
      search,
      workspace?.id ?? '',
      statusFilterKeys,
      dateFilter,
      abortController.signal,
    );
    if (data.aborted) {
      return;
    }
    setIsLoading(false);
    setJobs(data);
  };

  const exportCSV = async () => {
    setIsLoading(true);
    const data = await endpoint(
      1,
      jobs.count,
      'createdAt',
      'desc',
      search,
      workspace?.id ?? '',
      [],
      dateFilter,
      abortController.signal,
    );
    const headers = [
      'ID',
      'Title',
      'Due',
      'Assignee',
      'Organization',
      'Workspace',
      'Status',
      'Created',
      'Tags',
      'Duration',
      'Edits %',
    ];
    const array = data.data.map((data: TJob) => {
      const assignee = data?.assignee || data?.draftAssignee;
      return {
        id: data.id,
        title: data.name,
        due: data.deadline ? getAmericanTime(data.deadline, false) : '',
        assignee: assignee ? `${assignee.name} ${assignee.lastname} (${assignee.email})` : '',
        organization: organization?.name ?? '',
        workspace: workspace?.name ?? '',
        status: JOB_STATUSES_ENUM[data.status as keyof typeof JOB_STATUSES_ENUM],
        created: getAmericanTime(data.createdAt),
        tags: (data.tags ?? []).join(', '),
        duration: convertDuration(data.duration),
        editsPercent: Math.round(data.editingProgress || 0),
      };
    });
    if (data.aborted) {
      return;
    }
    setIsLoading(false);
    return downloadCSV(array, headers);
  };

  useEffect(() => {
    reload();
  }, [organization, workspace, currentTab, page, pageSize, orderBy, orderType, search, statusFilters, dateFilter]);

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, [
    workspaceId,
    organization,
    workspace,
    currentTab,
    page,
    pageSize,
    orderBy,
    orderType,
    search,
    statusFilters,
    dateFilter,
  ]);

  useEffect(() => {
    if (currentTab !== defaultTab) {
      setCurrentTab(defaultTab);
    }
  }, [organization?.id]);

  return (
    <PageContext.Provider
      value={{
        jobs,
        isLoading,
        reload,
        exportCSV,
        currentTab,
        setCurrentTab,
        checkedJobs,
        setCheckedJobs,
        anchorEl,
        setAnchorEl,
        orderBy,
        setOrderBy,
        orderType,
        setOrderType,
        statusFilters,
        setStatusFilters,
        dateFilter,
        setDateFilter,
        search,
        setSearch,
        page,
        setPage,
        pageSize,
        setPageSize,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => useContext(PageContext);
