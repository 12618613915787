import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { downloadCSV } from '@helpers/downloadCSV';

import { CustomTable } from '@components/Table';
import { ReportFilter } from '@components/todo/ReportFilter';
import { secondsToHHMMSS } from '@helpers/secondsToHHMMSS';
import { JOB_STATUSES_ENUM } from '@constants/enums/jobStatuses';
import { getAmericanTime } from '@helpers/getAmericanTimezone';
import { Header } from '@components/Header';
import { StatusText } from '@components/Table/StatusText';
import { LinearProgressLoader } from '@components/spinners/LinearProgressLoader';
import { useAuthContext } from '@providers/AuthProvider';
import { Popover } from '@components/Popover';
import { TJobsFilter } from '@components/todo/ReportFilter/types/TJobsFilter';
import { PagePagination } from '@components/Pagination';
import { useAPI } from '@hooks/useAPI';
import { ReportsService } from 'app/API';

const Content = styled.main`
  padding: 8px 30px 70px;
  min-height: calc(100vh - 120px);
`;
const CellContent = styled.div`
  margin: 0;
  padding: 20px 0;

  p {
    margin: 0;
  }
`;

export const UserJobsReport = () => {
  const { call } = useAPI();
  const [jobsReports, setJobsReports] = useState<any>({ data: [], count: 0 });
  const [submitTrigger, setSubmitTrigger] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const { userId } = useParams<{ userId: string }>();
  const { workspace, organization } = useAuthContext();
  const [jobsFilter, setJobsFilter] = useState<TJobsFilter>({
    from: undefined,
    to: undefined,
  });

  const handleSubmit = () => {
    setSubmitTrigger((v) => !v);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getPaginatedJobs = async () => {
    setIsLoading(true);
    const jobsFrom = Math.floor(Number(jobsFilter.from ?? null) / 1000) || '';
    const jobsTo = Math.floor(Number(jobsFilter.to ?? null) / 1000) || '';
    const jobs = await call(
      ReportsService.getUserJobsReport({
        wsid: workspace?.id ?? '',
        userId: userId ?? '',
        pageNo: (page ?? 1).toString(),
        pageSize: (pageSize ?? 25).toString(),
        orderBy: 'createdAt',
        orderType: 'desc',
        search: '',
        jobsFrom: jobsFrom.toString(),
        jobsTo: jobsTo.toString(),
      }),
    );
    setJobsReports(jobs);
    setIsLoading(false);
  };

  useEffect(() => {
    getPaginatedJobs();
  }, [jobsFilter.from, jobsFilter.to, organization, page, pageSize, workspace, userId, submitTrigger]);

  const onExportCsv = async () => {
    const jobsFrom = Math.floor(Number(jobsFilter.from ?? null) / 1000) || '';
    const jobsTo = Math.floor(Number(jobsFilter.to ?? null) / 1000) || '';
    const jobsReportsForExport = await call(
      ReportsService.getUserJobsReport({
        wsid: workspace?.id ?? '',
        userId: userId ?? '',
        pageNo: '1',
        pageSize: jobsReports.count.toString(),
        orderBy: 'createdAt',
        orderType: 'desc',
        search: '',
        jobsFrom: jobsFrom.toString(),
        jobsTo: jobsTo.toString(),
      }),
    );
    const headers = ['ID', 'Member', 'Job Title', 'Organization', 'Workspace', 'Status', 'Created', 'Duration'];
    const array = jobsReportsForExport.data.map((data: any) => {
      return {
        id: data.id,
        member: `${data.name} ${data.lastname} (${data.email})`,
        jobName: data.jobName,
        organization: organization?.name ?? '',
        workspace: workspace?.name ?? '',
        status: JOB_STATUSES_ENUM[data.status as keyof typeof JOB_STATUSES_ENUM],
        created: getAmericanTime(data.createdAt),
        duration: secondsToHHMMSS(data.duration),
      };
    });
    return downloadCSV(array, headers);
  };

  const tableHead = [
    {
      headComponent: () => <td style={{ width: '20%' }}>Member</td>,
      render: (data: any) => (
        <CellContent>
          <span style={{ display: 'block' }}>
            {data.name} {data.lastname}
          </span>
          <span style={{ display: 'block' }}>{data.email}</span>
        </CellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '20%' }}>Job Title</td>,
      render: (data: any) => <CellContent>{data.jobName}</CellContent>,
    },
    {
      headComponent: () => <td style={{ width: '20%' }}>Status</td>,
      render: ({ status }: { status: keyof typeof JOB_STATUSES_ENUM }) => (
        <CellContent>
          <StatusText status={JOB_STATUSES_ENUM[status]} />
        </CellContent>
      ),
    },
    {
      headComponent: () => <td style={{ width: '20%' }}>Created</td>,
      render: (data: any) => <CellContent>{getAmericanTime(data.createdAt)}</CellContent>,
    },
    {
      headComponent: () => <td style={{ width: '20%' }}>Duration</td>,
      render: (data: any) => <CellContent>{secondsToHHMMSS(data.duration)}</CellContent>,
    },
  ];

  const FilterPopover = () => {
    return (
      <Popover
        styles="margin-top: 40px; margin-left: -300px;"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ReportFilter
          jobsFilter={jobsFilter}
          jobsFilterOnChange={(e) => setJobsFilter(e)}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      </Popover>
    );
  };

  return (
    <>
      <Helmet>
        <title>User Jobs Report - AutoScript</title>
      </Helmet>
      <Header
        title="User Jobs Report"
        buttonLabel={jobsReports?.data?.length ? 'Export CSV' : undefined}
        buttonOnClick={jobsReports?.data?.length ? () => onExportCsv() : undefined}
        onFilterClick={(e) => setAnchorEl(e.currentTarget)}
        filterCounter={jobsFilter.from || jobsFilter.to ? 1 : 0}
      />

      <LinearProgressLoader active={isLoading} />
      <Content>
        <CustomTable data={jobsReports?.data ?? []} head={tableHead} />
        <PagePagination
          totalItemsCount={jobsReports.count ?? 0}
          page={page ?? 1}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </Content>

      <FilterPopover />
    </>
  );
};
